<template>
  <b-row class="match-height">

    <b-col
      md="4"
      lg="3"
    >
      archived tournaments

    </b-col>

  </b-row>

</template>

<script>
import {
  BCol, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCol,
    BRow,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      options: {},

    }
  },
  beforeCreate() {
    this.$http.get(`/organizer/tournaments?organization=${localStorage.getItem('organizationSelected')}&archived=true`).then(res => { this.tournaments = res.data.data })
  },
}
</script>
